/**
 * Created by jrnoguero on 02/10/2023.
 */
angular
    .module('annexaApp')
    .component('annexaSendToSignDocumentsConfig', {
        templateUrl: './components/admin/annexa-send-to-sign-documents-config/annexa-send-to-sign-documents-config.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;

			vm.deleteObject = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.documentTypes && vm.model.model.documentTypes.length > $index){
						vm.model.model.documentTypes.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
			
			vm.addObject = function(){
				var modal = angular.copy(CommonAdminModals.addConfigurationDocumentsSendToSign);
            	modal.annexaFormly.model = {};

				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(vm.model && vm.model.model && !vm.model.model.documentTypes){
							vm.model.model.documentTypes = [];
						}
            			
						if(modal.annexaFormly.model.modal_body.presetSignCircuit){
							var added = {documentTypeCode:modal.annexaFormly.model.modal_body.documentTypeCode, presetSignCircuit:(modal.annexaFormly.model.modal_body.presetSignCircuit) ? modal.annexaFormly.model.modal_body.presetSignCircuit : "", userIds:modal.annexaFormly.model.modal_body.userIds.split(",").map(function(x){return Number(x)}), signActionType:modal.annexaFormly.model.modal_body.signActionType, signatoryType:modal.annexaFormly.model.modal_body.signatoryType, maxDays:modal.annexaFormly.model.modal_body.maxDays};
						}else{
							var added ={documentTypeCode:modal.annexaFormly.model.modal_body.documentTypeCode, userIds:modal.annexaFormly.model.modal_body.userIds.split(",").map(function(x){return Number(x)}), signActionType:modal.annexaFormly.model.modal_body.signActionType, signatoryType:modal.annexaFormly.model.modal_body.signatoryType, maxDays:modal.annexaFormly.model.modal_body.maxDays};
						}
						
						vm.model.model.documentTypes.push(added);
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.editObject = function(object, $index){
				var modal = angular.copy(CommonAdminModals.addConfigurationDocumentsSendToSign);
            	modal.annexaFormly.model = {modal_body:{documentTypeCode:((object)?object.documentTypeCode:undefined), presetSignCircuit: ((object)?object.presetSignCircuit:0), userIds:((object)?object.userIds.join(','):[]), signActionType: ((object)?object.signActionType:0), signatoryType: ((object)?object.signatoryType:0), maxDays: ((object)?object.maxDays:0) }};
				var saveAddObject = function(modal){
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
    		        	if(vm.model && vm.model.model && !vm.model.model.documentTypes){
							vm.model.model.documentTypes = [];
						}
						if(vm.model.model.documentTypes.length > $index){
							if(modal.annexaFormly.model.modal_body.presetSignCircuit){
								vm.model.model.documentTypes[$index] = {documentTypeCode:modal.annexaFormly.model.modal_body.documentTypeCode, presetSignCircuit:(modal.annexaFormly.model.modal_body.presetSignCircuit) ? modal.annexaFormly.model.modal_body.presetSignCircuit : "", userIds:modal.annexaFormly.model.modal_body.userIds.split(",").map(function(x){return Number(x)}), signActionType:modal.annexaFormly.model.modal_body.signActionType, signatoryType:modal.annexaFormly.model.modal_body.signatoryType, maxDays:modal.annexaFormly.model.modal_body.maxDays};
							}else{
								vm.model.model.documentTypes[$index] ={documentTypeCode:modal.annexaFormly.model.modal_body.documentTypeCode, userIds:modal.annexaFormly.model.modal_body.userIds.split(",").map(function(x){return Number(x)}), signActionType:modal.annexaFormly.model.modal_body.signActionType, signatoryType:modal.annexaFormly.model.modal_body.signatoryType, maxDays:modal.annexaFormly.model.modal_body.maxDays};
							}
    		        			}
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}          
        }],
        bindings: {
            model: '='
        }
    });