/**
 * Created by aperezt on 05/09/2023.
 */
angular
    .module('annexaApp')
    .component('annexaCreateDocumentsConfig', {
        templateUrl: './components/admin/annexa-create-documents-config/annexa-create-documents-config.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;

			vm.deleteObject = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.reports && vm.model.model.reports.length > $index){
						vm.model.model.reports.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
			
			vm.addObject = function(){
				var modal = angular.copy(CommonAdminModals.addConfigurationDocumentsCreate);
            	modal.annexaFormly.model = {};

				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(vm.model && vm.model.model && !vm.model.model.reports){
							vm.model.model.reports = [];
						}
						var added = {reportCode:modal.annexaFormly.model.modal_body.reportCode, template:modal.annexaFormly.model.modal_body.template, name:modal.annexaFormly.model.modal_body.name, sectionId:modal.annexaFormly.model.modal_body.sectionId, profileIds:modal.annexaFormly.model.modal_body.profileIds.split(",").map(function(x){return Number(x)}), saveData:modal.annexaFormly.model.modal_body.saveData, nameAttachment:(modal.annexaFormly.model.modal_body.nameAttachment) ? modal.annexaFormly.model.modal_body.nameAttachment : ""};
						vm.model.model.reports.push(added);
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.editObject = function(object, $index){
				var modal = angular.copy(CommonAdminModals.addConfigurationDocumentsCreate);
            	modal.annexaFormly.model = {modal_body:{reportCode:((object)?object.reportCode:undefined), template: ((object)?object.template:0), name:((object)?object.template:undefined), sectionId:((object)?object.sectionId:0), profileIds:((object)?object.profileIds.join(','):[]), saveData:((object)?object.saveData:undefined), nameAttachment:((object)?object.nameAttachment:undefined)}};
				var saveAddObject = function(modal){
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
    		        	if(vm.model && vm.model.model && !vm.model.model.reports){
							vm.model.model.reports = [];
						}
						if(vm.model.model.reports.length > $index){
    		        		vm.model.model.reports[$index] = {reportCode:modal.annexaFormly.model.modal_body.reportCode, template:modal.annexaFormly.model.modal_body.template, name:modal.annexaFormly.model.modal_body.name, sectionId:modal.annexaFormly.model.modal_body.sectionId, profileIds:modal.annexaFormly.model.modal_body.profileIds.split(",").map(function(x){return Number(x)}), saveData:modal.annexaFormly.model.modal_body.saveData, nameAttachment:(modal.annexaFormly.model.modal_body.nameAttachment) ? modal.annexaFormly.model.modal_body.nameAttachment : ""};
						}
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}          
        }],
        bindings: {
            model: '='
        }
    });