/*** Created by adelolmo on 26/09/2023.*/
angular
    .module('annexaApp')
	.component ('annexaCreateNotificationStates', { 
        templateUrl: './components/admin/annexa-create-notification-states/annexa-create-notification-states.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;

            vm.deleteObjectStates = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.states && vm.model.model.states.length > $index){
						vm.model.model.states.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
            
            vm.editObjectStates = function(object, $index){
				var modal = angular.copy(CommonAdminModals.addCreateNotificationStates);
            	modal.annexaFormly.model = {modal_body:{stateId:((object)?object.stateId:undefined),stateIdName:((object)?object.stateIdName:undefined),stateDescription:((object)?object.stateDescription:undefined)}};
				var saveAddObject = function(modal){ 
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(vm.model && vm.model.model && !vm.model.model.states){
							vm.model.model.states = [];
						}
						if(vm.model.model.states.length > $index){
    		        		vm.model.model.states[$index] = {stateId:modal.annexaFormly.model.modal_body.stateId,stateIdName:modal.annexaFormly.model.modal_body.stateIdName,stateDescription:modal.annexaFormly.model.modal_body.stateDescription};
						} 
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);	
            }
			

			vm.addObjectStates = function(){
				var modal = angular.copy(CommonAdminModals.addCreateNotificationStates)
            	modal.annexaFormly.model = {};
				
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(!vm.model.model.states){
							vm.model.model.states = [];
						}
						var added = {stateId:modal.annexaFormly.model.modal_body.stateId,stateIdName:modal.annexaFormly.model.modal_body.stateIdName,stateDescription:modal.annexaFormly.model.modal_body.stateDescription};
						vm.model.model.states.push(added);
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			
            vm.deleteObjectObservationStates = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.observationStates && vm.model.model.observationStates.length > $index){
						vm.model.model.observationStates.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
            
            vm.editObjectObservationStates = function(object, $index){
				var modal = angular.copy(CommonAdminModals.addCreateNotificationObservationStates);
            	modal.annexaFormly.model = {modal_body:{state:((object)?object.state:undefined),literal:((object)?object.literal:undefined),showServiceId:((object)?object.showServiceId:false)}};
				var saveAddObject = function(modal){ 
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(vm.model && vm.model.model && !vm.model.model.observationStates){
							vm.model.model.observationStates = [];
						}
						if(vm.model.model.observationStates.length > $index){
    		        		vm.model.model.observationStates[$index] = {state:modal.annexaFormly.model.modal_body.state,literal:modal.annexaFormly.model.modal_body.literal,showServiceId:modal.annexaFormly.model.modal_body.showServiceId};
						}
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);	
            }
			

			vm.addObjectObservationStates = function(){
				var modal = angular.copy(CommonAdminModals.addCreateNotificationObservationStates)
            	modal.annexaFormly.model = {};
				
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(!vm.model.model.observationStates){
							vm.model.model.observationStates = [];
						}
						var added = {state:modal.annexaFormly.model.modal_body.state,literal:modal.annexaFormly.model.modal_body.literal,showServiceId:modal.annexaFormly.model.modal_body.showServiceId};
						vm.model.model.observationStates.push(added);
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.editObjectOtherStates = function(object, $index){
				var modal = angular.copy(CommonAdminModals.editCreateNotificationOtherStates);
            	modal.annexaFormly.model = {modal_body:{errorStates:((object)?object.errorStates:[]),maxErrorsCall:((object)?object.maxErrorsCall:0)}};
				var saveAddObject = function(modal){ 
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(vm.model && vm.model.model && !vm.model.model.errorStates){
							vm.model.model.errorStates = [];
						}
            			if(vm.model && vm.model.model && !vm.model.model.maxErrorsCall){
							vm.model.model.maxErrorsCall = 0;
						}
						if(modal.annexaFormly.model.modal_body.errorStates != vm.model.model.errorStates){
							vm.model.model.errorStates = modal.annexaFormly.model.modal_body.errorStates.split(",");
						} 
						if(modal.annexaFormly.model.modal_body.maxErrorsCall != vm.model.model.maxErrorsCall){
							vm.model.model.maxErrorsCall = modal.annexaFormly.model.modal_body.maxErrorsCall;
						}
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);	
            }
			          
        }],
        bindings: {
            model: '='
        }
    });