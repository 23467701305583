/*** Created by hfrancino on 09/08/2024.*/
angular
    .module('annexaApp')
	.component ('annexaRegisterEntryCreateDiligence', { 
        templateUrl: './components/admin/annexa-register-entry-create-diligence/annexa-register-entry-create-diligence.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;

            vm.deleteObject = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.reports && vm.model.model.reports.length > $index){
						vm.model.model.reports.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
            
            vm.addObject = function(){
				var modal = angular.copy(CommonAdminModals.addRegisterEntryCreateDiligence);
            	modal.annexaFormly.model = {};

            	var saveAddObject = function(modal) {
            	    if (modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body) {
            	        if (!vm.model.model.listValidRegisterEntry) {
            	            vm.model.model.listValidRegisterEntry = [];
            	        }

            	        var added = {
            	            registerEntryChannelCode: modal.annexaFormly.model.modal_body.registerEntryChannelCode,
            	            registerEntryOriginRegisterOrgan: modal.annexaFormly.model.modal_body.registerEntryOriginRegisterOrgan,
            	            definitionDiligence: {
            	                typeCode: modal.annexaFormly.model.modal_body.definitionDiligence.typeCode,
            	                profileAcronym: modal.annexaFormly.model.modal_body.definitionDiligence.profileAcronym,
            	                responsibleUser: modal.annexaFormly.model.modal_body.definitionDiligence.responsibleUser,
            	                state: modal.annexaFormly.model.modal_body.definitionDiligence.state
            	            }
            	        };

            	        vm.model.model.listValidRegisterEntry.push(added);
            	        modal.close();
            	    }
            	}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
            
            
            
        }],
        bindings: {
            model: '='
        }
    });