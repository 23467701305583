/**
 * Created by aarques on 04/01/2024.
 */
angular
    .module('annexaApp')
    .component('annexaAddConditionedSignCircuitTemplate', {
        templateUrl: './components/admin/annexa-add-conditioned-sign-circuit-template/annexa-add-conditioned-sign-circuit-template.html',
        controller: ['Language', '$rootScope', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'AdminFactory', 'CommonService', '$filter', 'CommonAdminModals', 'DialogsFactory', 'RestService',
        	function (Language, $rootScope, GlobalDataFactory, AnnexaFormlyFactory, AdminFactory, CommonService, $filter, CommonAdminModals, DialogsFactory, RestService) {
        	var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.getAutosignType = function (type) {
            	if(type) {
            		return type[Language.getActiveColumn()];
            	} else {
            		return "";
            	}
            }
            
            vm.addAction = function (){
            	var modal = angular.copy(CommonAdminModals.addConditionedSignCircuit);
            	modal.size = 'modal-md';
            	modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {circuitOrder: vm.conditionedSignCircuitList.length + 1};
                
                var circuitOrderField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'circuitOrder'");
                if(circuitOrderField) {
                	circuitOrderField.templateOptions.maxNumber = vm.conditionedSignCircuitList.length + 1;
                }
                var presetSignCircuitField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'presetSignCircuit'");
                if(presetSignCircuitField) {
                	presetSignCircuitField.templateOptions.labelProp = vm.languageColumn;
                	presetSignCircuitField.templateOptions.options = [];
                	_.forEach(GlobalDataFactory.presetSignCircuit, function (value, key) {
                		if(!$linq(vm.usedSignCircuits).contains(value.id)) {
                			presetSignCircuitField.templateOptions.options.push(value);
                		}
                    });
                }
                var editCircuitField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'editPresetSignCircuit'");
                if(editCircuitField) {
                	editCircuitField.templateOptions.options = [ { label: $filter('translate')('global.literals.editCircuit'), value: true }];
                }
				var addPreExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPreExpression'");
				if(addPreExpressionField) {
					addPreExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
						params.push({name:'dossierTransactions',
							type:{id: 'List', name: 'List'}, 
							typeList:{id: 'DossierTransaction', name: 'DossierTransaction'}, 
							id:''});
						params.push({name:'decrees',
							type:{id: 'List', name: 'List'}, 
							typeList:{id: 'Decree', name: 'Decree'}, 
							id:''});
						params.push({name:'sessions',
							type:{id: 'List', name: 'List'}, 
							typeList:{id: 'Session', name: 'Session'}, 
							id:''});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.spelCondition)?modal.annexaFormly.model.modal_body.spelCondition:undefined), params);
					};
				}
            	var addConditionedCircuit = function() {
            		var model = this.annexaFormly.model.modal_body;
            		if (model.presetSignCircuit) {
            			model.presetSignCircuit = $linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined, "x => x.id == " + model.presetSignCircuit);
                    }
            		if(vm.isEdit){
                		var modal = this;
                		var conditionedCircuit = {
                    			editPresetSignCircuit: ((model.editPresetSignCircuit_true)? true: false),
                    			presetSignCircuit: model.presetSignCircuit,
                    			circuitOrder: model.circuitOrder,
                    			spelCondition: model.spelCondition
                            };
                		if(vm.templateId) {
                			conditionedCircuit.template = {id:vm.templateId};
                		} else if(vm.secTemplateId) {
                			conditionedCircuit.secTemplate = {id:vm.secTemplateId};
                		} else if(vm.tramTemplateId) {
                			conditionedCircuit.tramTemplate = {id:vm.tramTemplateId};
                		}
                		RestService.insert('./api/doc/templateSignCircuit', conditionedCircuit).then(function (data) {
                			var index = vm.conditionedSignCircuitList.push(data) -1;
                			vm.chkAndUpdateCircuitOrder(model.circuitOrder, index);
                			vm.usedSignCircuits.push(data.presetSignCircuit.id);
                            modal.close();
	                    }).catch(function (error) {
	                    	modal.alerts.push({msg: $filter('translate')('global.errors.unknownNew')})
	                    });
                	} else {
                		var index = vm.conditionedSignCircuitList.push({
                			editPresetSignCircuit: ((model.editPresetSignCircuit_true)? true: false),
                			presetSignCircuit: model.presetSignCircuit,
                			circuitOrder: model.circuitOrder,
                			spelCondition: model.spelCondition
                        }) -1;
                		vm.chkAndUpdateCircuitOrder(model.circuitOrder, index);
                		vm.usedSignCircuits.push(model.presetSignCircuit.id);
                		this.close();
                	}
            	};
            	AnnexaFormlyFactory.showModal("modalAddConditionedSignCircuit", modal, addConditionedCircuit, false);
            }
            
            vm.editAction = function ($index){
            	var modal = angular.copy(CommonAdminModals.addConditionedSignCircuit);
            	modal.size = 'modal-md';
                modal.title = 'global.documents.modal.sendToSign.editUserThird';
            	modal.annexaFormly.model = {};
            	var presetSignCircuitIdAux = vm.conditionedSignCircuitList[$index].presetSignCircuit.id;
            	var circuitOrderField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'circuitOrder'");
                if(circuitOrderField) {
                	circuitOrderField.templateOptions.maxNumber = vm.conditionedSignCircuitList.length;
                }
            	var presetSignCircuitField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'presetSignCircuit'");
                if(presetSignCircuitField) {
                	presetSignCircuitField.templateOptions.labelProp = vm.languageColumn;
                	presetSignCircuitField.templateOptions.options = [];
                	_.forEach(GlobalDataFactory.presetSignCircuit, function (value, key) {
                		if(!$linq(vm.usedSignCircuits).contains(value.id) || value.id == presetSignCircuitIdAux) {
                    		presetSignCircuitField.templateOptions.options.push(value);
                		}
                    });
                }
                var editCircuitField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'editPresetSignCircuit'");
                if(editCircuitField) {
                	editCircuitField.templateOptions.options = [ { label: $filter('translate')('global.literals.editCircuit'), value: true }];
                }
				var addPreExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPreExpression'");
				if(addPreExpressionField) {
					addPreExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
						params.push({name:'dossierTransactions',
							type:{id: 'List', name: 'List'}, 
							typeList:{id: 'DossierTransaction', name: 'DossierTransaction'}, 
							id:''});
						params.push({name:'decrees',
							type:{id: 'List', name: 'List'}, 
							typeList:{id: 'Decree', name: 'Decree'}, 
							id:''});
						params.push({name:'sessions',
							type:{id: 'List', name: 'List'}, 
							typeList:{id: 'Session', name: 'Session'}, 
							id:''});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.spelCondition)?modal.annexaFormly.model.modal_body.spelCondition:undefined), params);
					};
				}
                modal.annexaFormly.model.modal_body = {
                		editPresetSignCircuit_true: vm.conditionedSignCircuitList[$index].editPresetSignCircuit,
            			presetSignCircuit: vm.conditionedSignCircuitList[$index].presetSignCircuit,
                		circuitOrder: vm.conditionedSignCircuitList[$index].circuitOrder,
            			spelCondition: vm.conditionedSignCircuitList[$index].spelCondition
                }
            	var editConditionedCircuit = function() {
                	var model = this.annexaFormly.model.modal_body;
            		if (model.presetSignCircuit && !model.presetSignCircuit.id) {
            			model.presetSignCircuit = $linq(GlobalDataFactory.presetSignCircuit).firstOrDefault(undefined, "x => x.id == " + model.presetSignCircuit);
                    }
                    vm.conditionedSignCircuitList[$index].editPresetSignCircuit = ((model.editPresetSignCircuit_true)? true: false);
                    vm.conditionedSignCircuitList[$index].presetSignCircuit = model.presetSignCircuit;
                    vm.conditionedSignCircuitList[$index].spelCondition = model.spelCondition;
                    vm.conditionedSignCircuitList[$index].circuitOrder = model.circuitOrder;
                	if(vm.isEdit){
                		var modal = this;
                		var conditionedCircuit = vm.conditionedSignCircuitList[$index];
                		RestService.update('./api/doc/templateSignCircuit/' + vm.conditionedSignCircuitList[$index].id, conditionedCircuit).then(function (data) {
                            vm.conditionedSignCircuitList[$index] = conditionedCircuit;
                            if(presetSignCircuitIdAux != conditionedCircuit.presetSignCircuit.id) {
                            	var indexUsedSC = $linq(vm.usedSignCircuits).indexOf("x => x == " + presetSignCircuitIdAux);
                            	vm.usedSignCircuits.splice(indexUsedSC, 1);
                            	vm.usedSignCircuits.push(conditionedCircuit.presetSignCircuit.id);
                            }
                            vm.chkAndUpdateCircuitOrder(model.circuitOrder, $index);
                            modal.close();
        				}).catch(function (error) {
        					console.error(error);
        				});
                	} else {
                        if(presetSignCircuitIdAux != model.presetSignCircuit.id) {
                        	var indexUsedSC = $linq(vm.usedSignCircuits).indexOf("x => x == " + presetSignCircuitIdAux);
                        	vm.usedSignCircuits.splice(indexUsedSC, 1);
                        	vm.usedSignCircuits.push(model.presetSignCircuit.id);
                        }
                        vm.chkAndUpdateCircuitOrder(model.circuitOrder, $index);
                		this.close();
                	}
            	};
            	AnnexaFormlyFactory.showModal("modalEditConditionedSignCircuit", modal, editConditionedCircuit, false);
            }
            
			vm.getShowRemoveAction = function (){
				if(vm.conditionedSignCircuitList.length > 0 || vm.removeAll){
					return true;
				}else{
					return false;
				}
			}
            
            vm.removeAction = function(index) {
            	if(vm.isEdit){
            		DialogsFactory.confirm('global.commonAdmin.modal.template.deleteMessage', 'global.commonAdmin.modal.template.deleteMessageConfirm')
            			.then(function (data) {
            				RestService.delete('./api/doc/templateSignCircuit/' + vm.conditionedSignCircuitList[index].id).then(function (data) {
            	            	for(var i = index; i < vm.conditionedSignCircuitList.length; i++) {
            	            		vm.conditionedSignCircuitList[i].circuitOrder = vm.conditionedSignCircuitList[i].circuitOrder -1;
                                }
            					var indexUsedSC = $linq(vm.usedSignCircuits).indexOf("x => x == " + vm.conditionedSignCircuitList[index].presetSignCircuit.id);
                            	vm.usedSignCircuits.splice(indexUsedSC, 1);
                        		vm.conditionedSignCircuitList.splice(index, 1);
            				}).catch(function (error) {
            					DialogsFactory.error($filter('translate')('global.commonAdmin.modal.template.errorDelete'));
            				});
            			}).catch(function (data) {});
	            } else {
	            	for(var i = index; i < vm.conditionedSignCircuitList.length; i++) {
	            		vm.conditionedSignCircuitList[i].circuitOrder = vm.conditionedSignCircuitList[i].circuitOrder -1;
                    }
                	var indexUsedSC = $linq(vm.usedSignCircuits).indexOf("x => x == " + vm.conditionedSignCircuitList[index].presetSignCircuit.id);
                	vm.usedSignCircuits.splice(indexUsedSC, 1);
	                vm.conditionedSignCircuitList.splice(index, 1);
	            }
            };
            
            vm.compare = function( a, b ) {
            	if ( a.circuitOrder < b.circuitOrder ){
            		return -1;
            	}
            	if ( a.circuitOrder > b.circuitOrder ){
            		return 1;
            	}
            	return 0;
            }
            
            vm.chkAndUpdateCircuitOrder = function(circuitOrder, index) {
            	if(circuitOrder != null){
            		var existOrder = false;
            		for(var i = 0; i < vm.conditionedSignCircuitList.length; i++){
                        if(i != index && vm.conditionedSignCircuitList[i].circuitOrder == circuitOrder){
                            existOrder = true;
                            break;
                        }
                    }
            		if(existOrder){
            			if(index + 1 < circuitOrder) {
            				for(var i = index; i < circuitOrder; i++){
                				var userCircuit = vm.conditionedSignCircuitList[i];
                				if(i != index) {
                					userCircuit.circuitOrder = userCircuit.circuitOrder - 1;
                				}
                			}
            			} else if (index + 1 > circuitOrder) {
            				for(var i = 0; i < index; i++){
                				var userCircuit = vm.conditionedSignCircuitList[i];
                				if(i != index && userCircuit.circuitOrder >= circuitOrder) {
                					userCircuit.circuitOrder = userCircuit.circuitOrder + 1;
                				}
                			}
            			}
            			vm.conditionedSignCircuitList.sort(vm.compare);
            		}
            	}
            }
					
            this.$onInit = function () {
            	vm.conditionedSignCircuitList = [];
            	vm.usedSignCircuits = [];
            	vm.isEdit = false;
            	vm.templateId;
    			vm.secTemplateId;
    			vm.tramTemplateId;
    			vm.removeAll = false;
    			if(vm.data) {
    				if(vm.data.conditionedSignCircuitList){
						vm.conditionedSignCircuitList = vm.data.conditionedSignCircuitList;
						vm.conditionedSignCircuitList.sort(vm.compare);
	                    _.forEach(vm.conditionedSignCircuitList, function (value, key) {
	                    	vm.usedSignCircuits.push(value.presetSignCircuit.id);
	                    });
					}
					if(vm.data.isEdit) {
						vm.isEdit = true;
						vm.templateId = vm.data.templateId;
		    			vm.secTemplateId = vm.data.secTemplateId;
		    			vm.tramTemplateId = vm.data.tramTemplateId;
					}
					if(vm.data.removeAll) {
						vm.removeAll = true;
					}
    			}
            }
        }],
        bindings: {
            data: '='
        }
    })
	
	