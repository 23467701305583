/*** Created by adelolmo on 13/09/2023.*/
//Por hacer
angular
    .module('annexaApp')
	.component('annexaLdapConnections', { //--annexaLdapConnections  annexaDecreeDocument
        templateUrl: './components/admin/annexa-ldap-connections/annexa-ldap-connections.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;
            
            vm.deleteObject = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.properties && vm.model.model.properties.length > $index){
						vm.model.model.properties.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
            
            vm.editObject = function(object, $index){
				var modal = angular.copy(CommonAdminModals.addLdapConnections);
            	modal.annexaFormly.model = {modal_body:{userSearchBase:((object)?object.userSearchBase:undefined),userSearchFilter:((object)?object.userSearchFilter:undefined),url:((object)?object.url:undefined),port:((object)?object.port:0),managerDn:((object)?object.managerDn:undefined),managerPassword:((object)?object.managerPassword:undefined)}};
				var saveAddObject = function(modal){
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(vm.model && vm.model.model && !vm.model.model.properties){
							vm.model.model.properties = [];
						}
						if(vm.model.model.properties.length > $index){
    		        		vm.model.model.properties[$index] = {userSearchBase:modal.annexaFormly.model.modal_body.userSearchBase,userSearchFilter:modal.annexaFormly.model.modal_body.userSearchFilter,url:modal.annexaFormly.model.modal_body.url,port:modal.annexaFormly.model.modal_body.port,managerDn:modal.annexaFormly.model.modal_body.managerDn,managerPassword:modal.annexaFormly.model.modal_body.managerPassword};
						}
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);	
            }
			

			vm.addObject = function(){
				var modal = angular.copy(CommonAdminModals.addLdapConnections);
            	modal.annexaFormly.model = {};
				
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(!vm.model.model.properties){
							vm.model.model.properties = [];
						}
						var added = {userSearchBase:modal.annexaFormly.model.modal_body.userSearchBase,userSearchFilter:modal.annexaFormly.model.modal_body.userSearchFilter,url:modal.annexaFormly.model.modal_body.url,port:modal.annexaFormly.model.modal_body.port,managerDn:modal.annexaFormly.model.modal_body.managerDn,managerPassword:modal.annexaFormly.model.modal_body.managerPassword};
						vm.model.model.properties.push(added);
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}	
			          
        }],
        bindings: {
            model: '='
        }
    });