angular
    .module('annexaApp')
    .component('annexaTableTramTemplates', {
        templateUrl: './components/admin/annexa-table-tram-templates/annexa-table-tram-templates.html',
        controller: ['CommonService', 'Language', 'RestService', '$rootScope', '$filter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'DialogsFactory','$scope', 'GlobalDataFactory', 'CacheFactory', 
            function (CommonService, Language, RestService, $rootScope, $filter, RestService, CommonAdminModals, AnnexaFormlyFactory, DialogsFactory, $scope, GlobalDataFactory, CacheFactory) {
                var vm = this;
                vm.languageColumn = Language.getActiveColumn();
                vm.addTemplate = function () {
                	var modal = angular.copy(CommonAdminModals.modalAddCreateTransactionTemplate);
                	modal.annexaFormly.model = {};
					var buttonField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpel'");
					if(buttonField){
						buttonField.templateOptions.executeFunction = function() {
							var params = [];
							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
							CommonService.executeExpression(((modal.annexaFormly.model && modal.annexaFormly.model.modal_body.spel)?modal.annexaFormly.model.modal_body.spel:undefined), params);
			        	};
					}
                	var tramTemplateField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'tramTemplate'");
                	if(tramTemplateField) {
                		if(vm.templates && vm.templates.length > 0) {
                    		tramTemplateField.templateOptions.tramTemplates = $linq(vm.templatesOptions).except(vm.templates, function(x,y){
                                if(x.tramTemplate && x.tramTemplate.id == y.id){
                                    return true;
                                }
                                return false;
                            }).toArray();
                		} else {
                    		tramTemplateField.templateOptions.tramTemplates = vm.templatesOptions;
                		}
                	}
                	AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, vm.saveTramTemplate, false);
                }
                
                vm.saveTramTemplate = function(modal){
                	if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.tramTemplate){
    		        	var template = $linq(vm.templatesOptions).firstOrDefault(undefined, "x => x.id == " + modal.annexaFormly.model.modal_body.tramTemplate);
		        		var spel = modal.annexaFormly.model.modal_body.spel;
		        		vm.templates.push({tramTemplate:template, spel: spel});
		        		modal.close();
                	}
                }

                vm.editTemplate = function (template, index) {
                	var modal = angular.copy(CommonAdminModals.modalAddCreateTransactionTemplate);
                	modal.title = 'global.commonAdmin.literals.editTemplate';
					var buttonField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpel'");
					if(buttonField){
						buttonField.templateOptions.executeFunction = function() {
							var params = [];
							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
							CommonService.executeExpression(((modal.annexaFormly.model && modal.annexaFormly.model.modal_body.spel)?modal.annexaFormly.model.modal_body.spel:undefined), params);
			        	};
					}
                	var actualTemplate = $linq(vm.templatesOptions).firstOrDefault(undefined, "x => x.id == " + template.tramTemplate.id);
					var doctype = undefined;
					if(template && template.tramTemplate && template.tramTemplate.documentType && template.tramTemplate.documentType.id){
						doctype = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == " + template.tramTemplate.documentType.id);	
					}
                	modal.annexaFormly.model = {modal_body: {tramTemplate: actualTemplate, index: index, typeSelect:doctype, spel:((template && template.spel)?template.spel:undefined)}};
                	var tramTemplateField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'tramTemplate'");
                	if(tramTemplateField) {
                		if(vm.templates && vm.templates.length > 0) {
                    		tramTemplateField.templateOptions.tramTemplates = $linq(vm.templatesOptions).except(vm.templates, function(x,y){
                                if(x.tramTemplate && x.tramTemplate.id == y.id && y.id != template.tramTemplate.id){
                                    return true;
                                }
                                return false;
                            }).toArray();
                		} else {
                    		tramTemplateField.templateOptions.tramTemplates = vm.templatesOptions;
                		}
                	}
                	AnnexaFormlyFactory.showModal('modalEditCreateTransactionTypeTemplate', modal, vm.updateTramTemplate, false);
                }
                
                vm.updateTramTemplate = function(modal){
                	if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.tramTemplate){
                		var template = undefined;
						if(modal.annexaFormly.model.modal_body.tramTemplate.id){
							template = $linq(vm.templatesOptions).firstOrDefault(undefined, "x => x.id == " + modal.annexaFormly.model.modal_body.tramTemplate.id);
						}else{
							template = $linq(vm.templatesOptions).firstOrDefault(undefined, "x => x.id == " + modal.annexaFormly.model.modal_body.tramTemplate);
						} 
						var spel = modal.annexaFormly.model.modal_body.spel;
    		        	if(template){
    		        		var templateIndex = modal.annexaFormly.model.modal_body.index;
    		        		if(templateIndex > -1){
								if(vm.templates && vm.templates.length > templateIndex && vm.templates[templateIndex]){
									vm.templates[templateIndex].tramTemplate = template;
									vm.templates[templateIndex].spel = spel;
								}else{
									vm.templates[templateIndex] = {tramTemplate:template, spel:spel};
								}
				        		modal.close();
    			        	}
    		        	}
                	}
                }
                
                vm.removeTemplate = function (template) {
                   	DialogsFactory.confirm('global.literals.deleteCreateTransactionTemplate', 'global.literals.deleteCreateTransactionTemplateConfirm').then(function (dataAux) {
               			if(template){
    		        		var templateIndex = -1;
    		        		if(template.id){
    		        			templateIndex = $linq(vm.templates).indexOf("x => x.tramTemplate.id == "+template.tramTemplate.id);
    		        		}
    		        		if(templateIndex > -1){
    				        	vm.templates.splice(templateIndex, 1);
    			        	}
    		        	}
                    }).catch(function (data) {
    	                    //Empty
    	            });
                }
            }],
            bindings: {
                templates: '=',
                isNew: '=',
                templatesOptions: '='
            }
        });
