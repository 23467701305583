angular
    .module('annexaApp')
    .component('annexaNotificationStatuses', {
        templateUrl: './components/admin/annexa-notification-statuses/annexa-notification-statuses.html',
        controller: ['$rootScope', 'Language', '$filter', 'RestService', function($rootScope, Language, $filter, RestService) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.requiredText = $filter('translate')('global.validation.required');
            vm.isNew = false;
            vm.inserted = undefined;
			vm.avaliableStatuses = [];
            vm.globalStatuses = [
                { id: 'PENDING', name: 'global.literals.PENDING' },
                { id: 'SEND', name: 'global.literals.SEND' },
                { id: 'COMPLETE', name: 'global.literals.COMPLETE' },
				{ id: 'ERROR', name: 'global.literals.ERROR' }
            ];
			
			vm.serviceStatusesAll = [];

            vm.updateStatus = function (rowformprofile) {
                _.forEach(vm.type.statuses, function(value, key) {
                    var languageNull = true;

                    _.forEach($rootScope.app.languagedef, function(val) {
                        if(value[val.column]) {
                            languageNull = false;
                        }
                    });

                    if(languageNull) {
                        vm.type.statuses.splice(key, 1);
                    }
                });
				rowformprofile.$cancel(); 
            }

            vm.removeStatus = function(index) {
                if(!vm.isNew && vm.type && vm.type.statuses && vm.type.statuses.length > index && vm.type.statuses[index].id) {
                    RestService.delete('./api/reg/notification_status/' + vm.type.statuses[index].id)
                        .then(function(data) {
                            vm.type.statuses.splice(index, 1);
                        }).catch(function(error) {
                            //Empty
                    });
                } else {
                    vm.type.statuses.splice(index, 1);
                }
            }

            vm.addStatus = function() {
                vm.inserted = {
                    language1: undefined,
                    language2: undefined,
                    language3: undefined,
                    allowEvidence: false
                }

                if(!vm.isNew) {
                    vm.inserted.type = vm.type;
                }

                vm.type.statuses.push(vm.inserted);
            }

            vm.editStatus = function (index) {
                //Empty
            }

            vm.checkName = function ($data, status, object) {
                var languageOk = true;

                _.forEach($rootScope.app.languagedef, function(val) {
                    if(!$data[val.column]) {
                        languageOk = false;
                    }
                });

                if(!languageOk) {
                    return vm.requiredText;
                } else {
                    status = $data;
                }
            }

            vm.checkAllowEvidence = function ($data, status, object) {
                status.allowEvidence = $data;
            }

            vm.checkGlobalStatus = function ($data, status, object) {
                status.globalStatus = $data;
            }

			vm.checkServiceStatus = function ($data, status, object) {
				status.finalServiceStatus = '';
				if(status.servicesStatus){
					status.servicesStatus.length = 0;
				}else{
					status.servicesStatus = [];
				}  
				if($data && $data.length > 0){
					_.forEach($data, function(st){
						status.servicesStatus.push(st);
					});
					var statusesAux = $linq(status.servicesStatus).select("x => x.id").toArray();
					status.finalServiceStatus = JSON.stringify(statusesAux);
					vm.getAvailableServicesStatus();
				}
                if(vm.isNew) {
                    $rootScope.$broadcast('AnnexaNotificationStatusAdded', { statuses: vm.type.statuses });
                } else {
                	if(status && status.type && status.type.id){
    					status.type = {id: status.type.id};
    				}
    				$rootScope.$broadcast('AnnexaNotificationStatusUpdated', { status: status, modifyAvailableStatus: vm.getAvailableServicesStatus });
                }
            }

            vm.printYesNo = function (value) {
                if(value == true) {
                    return '<i class="fa fa-check text-success"></i>';
                } else {
                    return '<i class="fa fa-remove text-danger"></i>';
                }
            }

			vm.printServiceStatus = function(statuses){
				var name = "";
				if(statuses && statuses.length > 0){
					_.forEach(statuses, function(status){
						name = name+((name)?", ":"")+status.name;
					});
				}
				return name;
			}
			
			vm.getAvailableServicesStatus = function(){
				if(!vm.isNew && vm.type && vm.type.statuses && vm.type.statuses.length > 0){
					_.forEach(vm.type.statuses, function(status, index){
						var	usedStatuses = [];
						_.forEach(vm.type.statuses, function(statusAux, indexAux){
							if(index !== indexAux && statusAux && statusAux.finalServiceStatus){
								var servicesStatus = JSON.parse(statusAux.finalServiceStatus);
								servicesStatus = $linq(vm.serviceStatusesAll).intersect(servicesStatus, "(x,y) => y.id && x == y.id").toArray();
								_.forEach(servicesStatus, function(ss){
									if(ss.id && !_.contains(usedStatuses,ss.id)){
										usedStatuses.push(ss.id);
									}
								})
							}
						});
						if(usedStatuses && usedStatuses.length > 0){
							vm.avaliableStatuses[status.id] = $linq(vm.serviceStatusesAll).except(usedStatuses, "(x,y) => y.id && x == y.id").toArray();
						}else{
							vm.avaliableStatuses[status.id] = angular.copy(vm.serviceStatusesAll);
						}
					});
					var	usedStatuses = [];
					_.forEach(vm.type.statuses, function(statusAux, indexAux){
						if(statusAux && statusAux.finalServiceStatus){
							var servicesStatus = JSON.parse(statusAux.finalServiceStatus);
							servicesStatus = $linq(vm.serviceStatusesAll).intersect(servicesStatus, "(x,y) => y.id && x == y.id").toArray();
							_.forEach(servicesStatus, function(ss){
								if(ss.id && !_.contains(usedStatuses,ss.id)){
									usedStatuses.push(ss.id);
								}
							})
						}
					});
					if(usedStatuses && usedStatuses.length > 0){
						vm.avaliableStatuses[-1] = $linq(vm.serviceStatusesAll).except(usedStatuses, "(x,y) => y.id && x == y.id").toArray();
					}else{
						vm.avaliableStatuses[-1] = angular.copy(vm.serviceStatusesAll);
					}
				}else if(!vm.isNew){
					vm.avaliableStatuses[-1] = angular.copy(vm.serviceStatusesAll);
				}
			}
			
			this.$onInit = function() {
				if(!vm.type) {
	                vm.type = { statuses: [] };
	                vm.isNew = true;
	            }else{
					vm.isNew = false;
				}
				vm.serviceStatusesAll.length = 0;
				vm.avaliableStatuses = {};
				var serviceStatus = $rootScope.app.configuration.create_notification_states;
				if(serviceStatus && serviceStatus.states && serviceStatus.states.length > 0){
					_.forEach(serviceStatus.states, function(state){
						vm.serviceStatusesAll.push({id:state.stateId, name:((state.stateDescription)?state.stateDescription:state.stateIdName)});
					});
				}
				if(vm.type && vm.type.statuses && vm.type.statuses.length > 0){
					_.forEach(vm.type.statuses, function(status){
						if(status && status.finalServiceStatus){
							var serviceStatus = JSON.parse(status.finalServiceStatus);
							status.servicesStatus = $linq(vm.serviceStatusesAll).intersect(serviceStatus, "(x,y) => y.id && x == y.id").toArray();
						}
					});
					vm.getAvailableServicesStatus();
				}
           }
        }],
        bindings: {
            type: '=?'
        }
    })