/**
 * Created by osirvent on 04/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaSequenceConfiguration', {
        templateUrl: './components/admin/annexa-sequence-configuration/annexa-sequence-configuration.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;
			vm.title = '';
			vm.canEditObject = false;
			vm.canEditYear = false;
			vm.canEditAll = false;

			vm.canShowTypes = false;
			vm.canShowYear = false;
			
			vm.editYear = function(year, $index){
				var modal = angular.copy(CommonAdminModals.addConfigurationYearSequence);
            	modal.annexaFormly.model = {modal_body:{year:((year)?year.year:undefined), seqYear: ((year)?year.seqYear:0)}};
				var saveAddYear = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.year != undefined && modal.annexaFormly.model.modal_body.seqYear != undefined){
    		        	if(vm.model && vm.model.model && !vm.model.model.sequencesYear){
							vm.model.model.sequencesYear = [];
						}
						if(vm.model.model.sequencesYear.length > $index){
    		        		vm.model.model.sequencesYear[$index] = {year:modal.annexaFormly.model.modal_body.year, seqYear: modal.annexaFormly.model.modal_body.seqYear};
						}else{
							vm.model.model.sequencesYear.push({year:modal.annexaFormly.model.modal_body.year, seqYear: modal.annexaFormly.model.modal_body.seqYear});
						}
						vm.model.model.seqAll = $linq(vm.model.model.sequencesYear).sum("x => x.seqYear");
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddYear, false);
			}
			
			vm.deleteYear = function(year, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteYearMessage', 'global.configuration.numbersConfiguration.deleteYearMessageConfirm').then(function (data) {
					if(vm.model && vm.model.model && vm.model.model.sequencesYear && vm.model.model.sequencesYear.length > $index){
						vm.model.model.sequencesYear.splice($index, 1);
						vm.model.model.seqAll = $linq(vm.model.model.sequencesYear).sum("x => x.seqYear");
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
			
			vm.addYear = function(){
				var modal = angular.copy(CommonAdminModals.addConfigurationYearSequence);
            	modal.annexaFormly.model = {};
				if(!vm.canEditYear){
					var seqYearField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'seqYear'");
	            	if(seqYearField) {
	               		seqYearField.hideExpression = function(){return true;}
	            	}
				}
				var saveAddYear = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.year != undefined){
    		        	if(vm.model && vm.model.model && !vm.model.model.sequencesYear){
							vm.model.model.sequencesYear = [];
						}
						vm.model.model.sequencesYear.push({year:modal.annexaFormly.model.modal_body.year, seqYear:((vm.canEditYear)?((modal.annexaFormly.model.modal_body.seqYear)?modal.annexaFormly.model.modal_body.seqYear:0):0)});
						vm.model.model.seqAll = $linq(vm.model.model.sequencesYear).sum("x => x.seqYear");
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddYear, false);
			}
			
			vm.editObject = function(object, $index, year){
				var modal = angular.copy(CommonAdminModals.addConfigurationTypeSequence);
            	modal.annexaFormly.model = {modal_body:{objectId:((object)?object.objectId:undefined), seqObject: ((object)?object.seqObject:0)}};
				var objectIdField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'objectId'");
            	if(objectIdField) {
               		objectIdField.templateOptions.options = ((vm.model && vm.model.types)?vm.model.types:[]);
					objectIdField.templateOptions.labelProp = ((vm.model && vm.model.typeDescription)?vm.model.typeDescription:'language1');
            	}
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.objectId && modal.annexaFormly.model.modal_body.seqObject != undefined){
						if(year){
							if(!year.sequencesObject){
								year.sequencesObject = [];
							}
							if(year.sequencesObject.length > $index){
	    		        		year.sequencesObject[$index] = {objectId:modal.annexaFormly.model.modal_body.objectId, seqObject: modal.annexaFormly.model.modal_body.seqObject};
							}else{
								year.sequencesObject.push({objectId:modal.annexaFormly.model.modal_body.objectId, seqObject: modal.annexaFormly.model.modal_body.seqObject});
							}
							year.seqYear = $linq(year.sequencesObject).sum("x => x.seqObject");
							vm.model.model.seqAll = $linq(vm.model.model.sequencesYear).sum("x => x.seqYear");
							vm.model.model.sequencesObject = [];
						}else{
							if(!vm.model.model.sequencesObject){
								vm.model.model.sequencesObject = [];
							}
							if(vm.model.model.sequencesObject.length > $index){
	    		        		vm.model.model.sequencesObject[$index] = {objectId:modal.annexaFormly.model.modal_body.objectId, seqObject: modal.annexaFormly.model.modal_body.seqObject};
							}else{
								vm.model.model.sequencesObject.push({objectId:modal.annexaFormly.model.modal_body.objectId, seqObject: modal.annexaFormly.model.modal_body.seqObject});
							}
							vm.model.model.seqAll = $linq(vm.model.model.sequencesObject).sum("x => x.seqObject");
							vm.model.model.sequencesYear = [];
						}
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.deleteObject = function(object, $index, year){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(year){
						if(year.sequencesObject && year.sequencesObject.length > $index){
							year.sequencesObject.splice($index, 1);
							year.seqYear = $linq(year.sequencesObject).sum("x => x.seqObject");
							vm.model.model.seqAll = $linq(vm.model.model.sequencesYear).sum("x => x.seqYear");
						}
					}else {
						if(vm.model.model.sequencesObject && vm.model.model.sequencesObject.length > $index){
							vm.model.model.sequencesObject.splice($index, 1);
							vm.model.model.seqAll = $linq(vm.model.model.sequencesObject).sum("x => x.seqObject");
						}
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
			
			vm.addObject = function(year){
				var modal = angular.copy(CommonAdminModals.addConfigurationTypeSequence);
            	modal.annexaFormly.model = {};
				var objectIdField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'objectId'");
            	if(objectIdField) {
               		objectIdField.templateOptions.options = ((vm.model && vm.model.types)?vm.model.types:[]);
					objectIdField.templateOptions.labelProp = ((vm.model && vm.model.typeDescription)?vm.model.typeDescription:'language1');
            	}

				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.objectId && modal.annexaFormly.model.modal_body.seqObject != undefined){
						if(year){
							if(!year.sequencesObject){
								year.sequencesObject = [];
							}
	    		        	year.sequencesObject.push({objectId:modal.annexaFormly.model.modal_body.objectId, seqObject: modal.annexaFormly.model.modal_body.seqObject});
							year.seqYear = $linq(year.sequencesObject).sum("x => x.seqObject");
							vm.model.model.seqAll = $linq(vm.model.model.sequencesYear).sum("x => x.seqYear");
							vm.model.model.sequencesObject = [];
						}else{
							if(!vm.model.model.sequencesObject){
								vm.model.model.sequencesObject = [];
							}
	    		        	vm.model.model.sequencesObject.push({objectId:modal.annexaFormly.model.modal_body.objectId, seqObject: modal.annexaFormly.model.modal_body.seqObject});
							vm.model.model.seqAll = $linq(vm.model.model.sequencesObject).sum("x => x.seqObject");
							vm.model.model.sequencesYear = [];
						}
		        		modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.editAll = function(){
				var modal = angular.copy(CommonAdminModals.addConfigurationAllSequence);
            	modal.annexaFormly.model = {modal_body:{all:((vm.model && vm.model.model)?vm.model.model.seqAll:0)}};
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.all != undefined){
						vm.model.model.seqAll = modal.annexaFormly.model.modal_body.all;
						vm.model.model.sequencesYear = [];
						vm.model.model.sequencesObject = [];
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.getObjectName = function(objectId){
				if(vm.model && vm.model.types && vm.model.typeDescription){
					var type = $linq(vm.model.types).firstOrDefault(undefined, 'x => x.id == '+objectId);
					if(type && type[vm.model.typeDescription]){
						return type[vm.model.typeDescription];
					}else{
						return objectId;
					}
					
				}else{
					return objectId;
				}	
			}
			
			this.$onInit = function() {
				if(vm.model && vm.model.objectType){
					vm.model.sequenceType = ((vm.model && vm.model.sequenceType)?vm.model.sequenceType.toUpperCase():vm.model.sequenceType);
					if(vm.model.objectType === 'DECREE' || vm.model.objectType === 'SESSION'){
						vm.canEditObject = true;
						if(!vm.model.sequenceType || (vm.model.sequenceType !== 'GLOBAL' && vm.model.sequenceType !== 'ANUAL')){
							vm.model.sequenceType = 'ANUAL';
						}
						if(vm.model.sequenceType){
							vm.title = 'global.configuration.numbersConfiguration.'+vm.model.sequenceType;
						}
					} else if(vm.model.objectType === 'PROPOSAL'){
						if(!vm.model.sequenceType || (vm.model.sequenceType !== 'GLOBAL' && vm.model.sequenceType !== 'ANUAL')){
							vm.model.sequenceType = 'ANUAL';
						}
						if(vm.model.sequenceType === 'GLOBAL'){
							vm.canEditAll = true;
						}else{
							vm.canEditYear = true;
						}
						if(vm.model.sequenceType){
							vm.title = 'global.configuration.numbersConfiguration.'+vm.model.sequenceType.toUpperCase();
						}
					} else if(vm.model.objectType === 'DOSSIER'){
						if(!vm.model.sequenceType || (vm.model.sequenceType !== 'PROCEDURE' && vm.model.sequenceType !== 'PROCEDURE_ALL' && vm.model.sequenceType !== 'PROCEDURE_GLOBAL' && vm.model.sequenceType !== 'PROCEDURE_GLOBAL_ALL' && vm.model.sequenceType !== 'GLOBAL_ALL' && vm.model.sequenceType !== 'GLOBAL')){
							vm.model.sequenceType = 'GLOBAL';
						}
						if(vm.model.sequenceType === 'PROCEDURE' || vm.model.sequenceType === 'PROCEDURE_ALL' || vm.model.sequenceType === 'PROCEDURE_GLOBAL' || vm.model.sequenceType === 'PROCEDURE_GLOBAL_ALL'){
							vm.canEditObject = true;
						}else if(vm.model.sequenceType === 'GLOBAL_ALL'){
							vm.canEditAll = true;
						}else if(vm.model.sequenceType === 'GLOBAL'){
							vm.canEditYear = true;
						}
						if(vm.model.sequenceType){
							vm.title = 'global.configuration.numbersConfiguration.'+((vm.model.sequenceType === 'GLOBAL')?vm.model.sequenceType+'_DOSSIER':vm.model.sequenceType);
						}
					} else if(vm.model.objectType === 'TASK'){
						if(!vm.model.sequenceType || (vm.model.sequenceType !== 'TASK_TYPE' && vm.model.sequenceType !== 'TASK_TYPE_ALL' && vm.model.sequenceType !== 'TASK_TYPE_GLOBAL' && vm.model.sequenceType !== 'TASK_TYPE_GLOBAL_ALL' && vm.model.sequenceType !== 'GLOBAL_ALL' && vm.model.sequenceType !== 'GLOBAL')){
							vm.model.sequenceType = 'GLOBAL';
						}
						if(vm.model.sequenceType === 'TASK_TYPE' || vm.model.sequenceType === 'TASK_TYPE_ALL' || vm.model.sequenceType === 'TASK_TYPE_GLOBAL' || vm.model.sequenceType === 'TASK_TYPE_GLOBAL_ALL'){
							vm.canEditObject = true;
						}else if(vm.model.sequenceType === 'GLOBAL_ALL'){
							vm.canEditAll = true;
						}else if(vm.model.sequenceType === 'GLOBAL'){
							vm.canEditYear = true;
						}
						if(vm.model.sequenceType){
							vm.title = 'global.configuration.numbersConfiguration.'+((vm.model.sequenceType === 'GLOBAL')?vm.model.sequenceType+'_TASK':vm.model.sequenceType);
						}
					}
					if(vm.canEditObject && vm.model && (vm.model.objectType === 'DECREE' || vm.model.objectType === 'SESSION') && vm.model.sequenceType && vm.model.sequenceType === 'GLOBAL'){
						vm.canShowTypes = true;
					}else if(vm.model && (vm.model.objectType === 'DECREE' || vm.model.objectType === 'SESSION' || vm.model.objectType === 'PROPOSAL') && vm.model.sequenceType && vm.model.sequenceType === 'ANUAL'){
						vm.canShowYear = true;						
					}else if(vm.model && vm.model.objectType === 'DOSSIER' && vm.model.sequenceType && vm.model.sequenceType === 'GLOBAL'){
						vm.canShowYear = true;						
					}else if(vm.model && vm.model.objectType === 'DOSSIER' && vm.model.sequenceType && vm.model.sequenceType === 'GLOBAL_ALL'){
						//Sol es mostra el all i es modifica					
					}else if(vm.model && vm.model.objectType === 'DOSSIER' && vm.model.sequenceType && (vm.model.sequenceType === 'PROCEDURE' || vm.model.sequenceType === 'PROCEDURE_GLOBAL')){
						vm.canShowYear = true;					
					}else if(vm.model && vm.model.objectType === 'DOSSIER' && vm.model.sequenceType && (vm.model.sequenceType === 'PROCEDURE_ALL' || vm.model.sequenceType === 'PROCEDURE_GLOBAL_ALL')){
						vm.canShowTypes = true;					
					}else if(vm.model && vm.model.objectType === 'TASK' && vm.model.sequenceType && vm.model.sequenceType === 'GLOBAL'){
						vm.canShowYear = true;						
					}else if(vm.model && vm.model.objectType === 'TASK' && vm.model.sequenceType && vm.model.sequenceType === 'GLOBAL_ALL'){
						//Sol es mostra el all i es modifica					
					}else if(vm.model && vm.model.objectType === 'TASK' && vm.model.sequenceType && (vm.model.sequenceType === 'TASK_TYPE' || vm.model.sequenceType === 'TASK_TYPE_GLOBAL')){
						vm.canShowYear = true;					
					}else if(vm.model && vm.model.objectType === 'TASK' && vm.model.sequenceType && (vm.model.sequenceType === 'TASK_TYPE_ALL' || vm.model.sequenceType === 'TASK_TYPE_GLOBAL_ALL')){
						vm.canShowTypes = true;					
					
					}
				}
			}            
        }],
        bindings: {
            model: '='
        }
    })
	.component('annexaRegisterEntrySequenceConfiguration', {
        templateUrl: './components/admin/annexa-sequence-configuration/annexa-register-entry-sequence-configuration.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;
			vm.editObject = function(object, $index){
				if(object && object.id){
					var modal = angular.copy(CommonAdminModals.addConfigurationTypeSequenceRegisterEntry);
	            	modal.annexaFormly.model = {modal_body:{id:object.id, yearIn:object.yearIn, seqIn: object.seqIn, yearOut:object.yearOut, seqOut: object.seqOut}};
					var objectIdField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'id'");
	            	if(objectIdField) {
						var typesAux = [];
						if(vm.model && vm.model.types){
							if(vm.model.model && vm.model.model.sequences){
								_.forEach(vm.model.types, function(type){
									var taux = $linq(vm.model.model.sequences).firstOrDefault(undefined, "x => x.id == "+type.id+" && x.id != "+object.id);
									if(!taux){
										typesAux.push(type);
									}
								});
							}else{
								typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
							}
						}else{
							typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
						}
	               		objectIdField.templateOptions.options = typesAux;
						objectIdField.templateOptions.labelProp = ((vm.model && vm.model.typeDescription)?vm.model.typeDescription:'language1');
	            	}
					var saveAddObject = function(modal){
						var self = this;
						if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.id){
	            			if(!vm.model.model.sequences){
								vm.model.model.sequences = [];
							}
							if(vm.model.model.sequences.length > $index){
	    		        		vm.model.model.sequences[$index] = {id:modal.annexaFormly.model.modal_body.id, yearIn:modal.annexaFormly.model.modal_body.yearIn, seqIn: modal.annexaFormly.model.modal_body.seqIn, yearOut:modal.annexaFormly.model.modal_body.yearOut, seqOut: modal.annexaFormly.model.modal_body.seqOut};
							}else{
								vm.model.model.sequences.push({id:modal.annexaFormly.model.modal_body.id, yearIn:modal.annexaFormly.model.modal_body.yearIn, seqIn: modal.annexaFormly.model.modal_body.seqIn, yearOut:modal.annexaFormly.model.modal_body.yearOut, seqOut: modal.annexaFormly.model.modal_body.seqOut});
							}
							modal.close();
	                	}
					}
					AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
				}
				
				
				
			}
			
			vm.deleteObject = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.sequences && vm.model.model.sequences.length > $index){
						vm.model.model.sequences.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
			
			vm.addObject = function(){
				var modal = angular.copy(CommonAdminModals.addConfigurationTypeSequenceRegisterEntry);
            	modal.annexaFormly.model = {};
				var objectIdField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'id'");
            	if(objectIdField) {
               		var typesAux = [];
					if(vm.model && vm.model.types){
						if(vm.model.model && vm.model.model.sequences){
							_.forEach(vm.model.types, function(type){
								var taux = $linq(vm.model.model.sequences).firstOrDefault(undefined, "x => x.id == "+type.id);
								if(!taux){
									typesAux.push(type);
								}
							});
						}else{
							typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
						}
					}else{
						typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
					}
               		objectIdField.templateOptions.options = typesAux;
					objectIdField.templateOptions.labelProp = ((vm.model && vm.model.typeDescription)?vm.model.typeDescription:'language1');
            	}
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.id){
            			if(!vm.model.model.sequences){
							vm.model.model.sequences = [];
						}
						vm.model.model.sequences.push({id:modal.annexaFormly.model.modal_body.id, yearIn:modal.annexaFormly.model.modal_body.yearIn, seqIn: modal.annexaFormly.model.modal_body.seqIn, yearOut:modal.annexaFormly.model.modal_body.yearOut, seqOut: modal.annexaFormly.model.modal_body.seqOut});
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.getObjectName = function(objectId){
				if(vm.model && vm.model.types && vm.model.typeDescription){
					var type = $linq(vm.model.types).firstOrDefault(undefined, 'x => x.id == '+objectId);
					if(type && type[vm.model.typeDescription]){
						return type[vm.model.typeDescription];
					}else{
						return objectId;
					}
					
				}else{
					return objectId;
				}	
			}
			
			this.$onInit = function() {
				if(vm.model && vm.model.objectType){
					if(vm.model.objectType === 'REGISTE_ENTRY'){
					}
				}
			}           
        }],
        bindings: {
            model: '='
        }
    })
	.component('annexaSequenceConfigurationPluginDecree', {
        templateUrl: './components/admin/annexa-sequence-configuration/annexa-sequence-configuration-plugin-decree.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;
			vm.editObject = function(object, $index){
				if(object && object.idDocumentType){
					var modal = angular.copy(CommonAdminModals.addConfigurationTypeSequenceDecreePlugin);
					var showYear = vm.showYearConfigPlugin(object.idDocumentType);
	            	modal.annexaFormly.model = {modal_body:{idDocumentType:object.idDocumentType, seq: object.seq}};
					if(showYear){
						modal.annexaFormly.model.modal_body.year = object.year;
					}
					var yearField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'year'");
		            if(yearField) {
		            	yearField.hideExpression = function($viewValue, $modelValue, scope) {
							if(scope && scope.model && scope.model.idDocumentType){
								return ((vm.showYearConfigPlugin(scope.model.idDocumentType))?false:true);
							}
							return true;
						}
					}
					var objectIdField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'idDocumentType'");
	            	if(objectIdField) {
						var typesAux = [];
						if(vm.model && vm.model.types){
							if(vm.model.model && vm.model.model.listConfigurationDocument){
								_.forEach(vm.model.types, function(type){
									var taux = $linq(vm.model.model.listConfigurationDocument).firstOrDefault(undefined, "x => x.idDocumentType == "+type.id+" && x.idDocumentType != "+object.idDocumentType);
									if(!taux){
										typesAux.push(type);
									}
								});
							}else{
								typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
							}
						}else{
							typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
						}
	               		objectIdField.templateOptions.options = typesAux;
						objectIdField.templateOptions.labelProp = ((vm.model && vm.model.typeDescription)?vm.model.typeDescription:'language1');
	            	}
					var saveAddObject = function(modal){
						var self = this;
						if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.idDocumentType && modal.annexaFormly.model.modal_body.seq != undefined){
	            			if(!vm.model.model.listConfigurationDocument){
								vm.model.model.listConfigurationDocument = [];
							}
							if(vm.model.model.listConfigurationDocument.length > $index){
	    		        		vm.model.model.listConfigurationDocument[$index] = {idDocumentType:modal.annexaFormly.model.modal_body.idDocumentType, seq: modal.annexaFormly.model.modal_body.seq};
								if(modal.annexaFormly.model.modal_body.year){
									vm.model.model.listConfigurationDocument[$index].year =  modal.annexaFormly.model.modal_body.year;
								}
							}else{
								var added = {idDocumentType:modal.annexaFormly.model.modal_body.idDocumentType, seq: modal.annexaFormly.model.modal_body.seq};
								if(modal.annexaFormly.model.modal_body.year){
									added.year =  modal.annexaFormly.model.modal_body.year;
								}
								vm.model.model.listConfigurationDocument.push(added);
							}
							modal.close();
	                	}
					}
					AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
				}
				
				
				
			}
			
			vm.deleteObject = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.listConfigurationDocument && vm.model.model.listConfigurationDocument.length > $index){
						vm.model.model.listConfigurationDocument.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
			
			vm.addObject = function(){
				var modal = angular.copy(CommonAdminModals.addConfigurationTypeSequenceDecreePlugin);
				var showYear = false;
            	modal.annexaFormly.model = {};
				var yearField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'year'");
	            if(yearField) {
	            	yearField.hideExpression = function($viewValue, $modelValue, scope) {
						if(scope && scope.model && scope.model.idDocumentType){
							return ((vm.showYearConfigPlugin(scope.model.idDocumentType))?false:true);
						}
						return true;
					}
				}
				var objectIdField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'idDocumentType'");
            	if(objectIdField) {
               		var typesAux = [];
					if(vm.model && vm.model.types){
						if(vm.model.model && vm.model.model.listConfigurationDocument){
							_.forEach(vm.model.types, function(type){
								var taux = $linq(vm.model.model.listConfigurationDocument).firstOrDefault(undefined, "x => x.idDocumentType == "+type.id);
								if(!taux){
									typesAux.push(type);
								}
							});
						}else{
							typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
						}
					}else{
						typesAux = ((vm.model && vm.model.types)?vm.model.types:[]);
					}
               		objectIdField.templateOptions.options = typesAux;
					objectIdField.templateOptions.labelProp = ((vm.model && vm.model.typeDescription)?vm.model.typeDescription:'language1');
            	}
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.idDocumentType && modal.annexaFormly.model.modal_body.seq != undefined){
            			if(!vm.model.model.listConfigurationDocument){
							vm.model.model.listConfigurationDocument = [];
						}
						var added = {idDocumentType:modal.annexaFormly.model.modal_body.idDocumentType, seq: modal.annexaFormly.model.modal_body.seq};
						if(modal.annexaFormly.model.modal_body.year){
							added.year =  modal.annexaFormly.model.modal_body.year;
						}
						vm.model.model.listConfigurationDocument.push(added);
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}
			
			vm.getObjectName = function(objectId){
				if(vm.model && vm.model.types && vm.model.typeDescription){
					var type = $linq(vm.model.types).firstOrDefault(undefined, 'x => x.id == '+objectId);
					if(type && type[vm.model.typeDescription]){
						return type[vm.model.typeDescription];
					}else{
						return objectId;
					}
					
				}else{
					return objectId;
				}	
			}
			
			vm.showYearConfigPlugin = function(objectId){
				if(vm.model && vm.model.types && vm.model.typeDescription){
					var type = $linq(vm.model.types).firstOrDefault(undefined, 'x => x.id == '+objectId);
					if(type && type.showYearConfigPlugin){
						return type.showYearConfigPlugin;
					}else{
						return false;
					}
				}else{
					return false;
				}	
			}
			
			this.$onInit = function() {
				if(vm.model && vm.model.objectType){
					if(vm.model.objectType === 'DECREE'){
					}
				}
			}            
        }],
        bindings: {
            model: '='
        }
    });