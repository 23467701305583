/*** Created by adelolmo on 08/09/2023.*/
angular
    .module('annexaApp')
	.component('annexaDecreeDocument', {
        templateUrl: './components/admin/annexa-decree-document/annexa-decree-document.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'CommonAdminModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'CommonService', 'dialogs', 'SecFactory', 'AdminFactory', 'DialogsFactory', '$scope', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, CommonAdminModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, CommonService, dialogs, SecFactory, AdminFactory, DialogsFactory, $scope) {
            var vm = this;
            
            vm.deleteObject = function(object, $index){
				DialogsFactory.confirm('global.configuration.numbersConfiguration.deleteObjectMessage', 'global.configuration.numbersConfiguration.deleteObjectMessageConfirm').then(function (data) {
					if(vm.model.model.docTypes && vm.model.model.docTypes.length > $index){
						vm.model.model.docTypes.splice($index, 1);
					}
	           }).catch(function (data) {
		               //Empty
		       });
			}
            
            vm.editObject = function(object, $index){
				var modal = angular.copy(CommonAdminModals.addConfigurationDecreeDocument);
            	modal.annexaFormly.model = {modal_body:{id:((object)?object.id:0),extract:((object)?object.extract:undefined),number:((object)?object.number:undefined),date:((object)?object.date:undefined),tipusDelegat:((object)?object.tipusDelegat:undefined),motiuAnulacio:((object)?object.motiuAnulacio:undefined),dataAnulacio:((object)?object.dataAnulacio:undefined)}};
				var saveAddObject = function(modal){
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(vm.model && vm.model.model && !vm.model.model.docTypes){
							vm.model.model.docTypes = [];
						}
						if(vm.model.model.docTypes.length > $index){
    		        		vm.model.model.docTypes[$index] = {id:modal.annexaFormly.model.modal_body.id,extract:modal.annexaFormly.model.modal_body.extract,number:modal.annexaFormly.model.modal_body.number,date:modal.annexaFormly.model.modal_body.date,tipusDelegat:(modal.annexaFormly.model.modal_body.tipusDelegat)?modal.annexaFormly.model.modal_body.tipusDelegat:"",motiuAnulacio:(modal.annexaFormly.model.modal_body.motiuAnulacio)?modal.annexaFormly.model.modal_body.motiuAnulacio:"",dataAnulacio:(modal.annexaFormly.model.modal_body.dataAnulacio)?modal.annexaFormly.model.modal_body.dataAnulacio:""};
						}
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);	
		}
			

			vm.addObject = function(){
				var modal = angular.copy(CommonAdminModals.addConfigurationDecreeDocument)
            	modal.annexaFormly.model = {};
				
				var saveAddObject = function(modal){
					var self = this;
					if(modal && modal.annexaFormly && modal.annexaFormly.model && modal.annexaFormly.model.modal_body){
            			if(!vm.model.model.docTypes){
							vm.model.model.docTypes = [];
						}
						var added = {id:modal.annexaFormly.model.modal_body.id,extract:modal.annexaFormly.model.modal_body.extract,number:modal.annexaFormly.model.modal_body.number,date:modal.annexaFormly.model.modal_body.date,tipusDelegat:(modal.annexaFormly.model.modal_body.tipusDelegat)?modal.annexaFormly.model.modal_body.tipusDelegat:"",motiuAnulacio:(modal.annexaFormly.model.modal_body.motiuAnulacio)?modal.annexaFormly.model.modal_body.motiuAnulacio:"",dataAnulacio:(modal.annexaFormly.model.modal_body.dataAnulacio)?modal.annexaFormly.model.modal_body.dataAnulacio:""};
						vm.model.model.docTypes.push(added);
						modal.close();
                	}
				}
				AnnexaFormlyFactory.showModal('modalNewCreateTransactionTypeTemplate', modal, saveAddObject, false);
			}	
			          
        }],
        bindings: {
            model: '='
        }
    });